.loading-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 365px;
  height: 100%;
}
.header-add-role {
  width: 100%;
  padding: 16px 24px;
  display: flex;
  align-items: center;
}

.table-role .ant-table-pagination.ant-pagination {
  margin-bottom: 0px !important;
}

.ant-tree-switcher {
  display: none;
}

.setting-role-form-permission-item {
  margin-left: 24px;
}

.divider-role .ant-divider-horizontal {
  margin: 24px 0px !important;
}
