.header-edit-rate-plan {
  display: flex;
  align-items: center;
  padding: 20px 24px;
}

.header-room-types {
  padding: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fafafa;
  border-bottom: 1px solid #f0f0f0;
}
.body-room-types {
  padding: 16px;
}
.divider-rate-plan .ant-divider-horizontal {
  margin-bottom: 24px !important;
  margin-top: 0px !important;
}
