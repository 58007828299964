.site-layout-background {
  background: #fff !important;
  padding: 0 !important;
  padding-left: 20px;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #f0f0f0;
}

.site-layout-background-hotel {
  background: #fff !important;
  padding: 0 !important;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
}

.ant-menu-horizontal {
  background: #fff !important;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-bottom: none !important;
}

.hotel-name {
  font-size: medium;
  font-weight: bold;
  margin-left: 24px;
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hotelSearch {
  margin-top: 20px;
}

.left-container {
  margin-left: 20px;
}

.right-container {
  display: flex;
  align-items: center;
  width: 50%;
}

.ant-menu-submenu-title {
  height: 64px !important;
}

.user-name {
  color: #000000;
  margin: 0px 8px;
}

.right-sub-menu-title {
  display: flex;
  margin-right: 4;
  align-items: center;
  justify-content: space-between;
}

.list-menu-header {
  padding: 0px 24px !important;
}
