.header-banners {
  width: 100%;
  padding: 20px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loading-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.ck.ck-editor__main > .ck-editor__editable {
  height: 100px !important;
}
.table-banners .ant-table-pagination.ant-pagination {
  margin-bottom: 0px !important;
}
